
import DefaultCardLayout from "@/components/Card/DefaultCardLayout.vue";
import DefaultButton from "@/components/Button/DefaultButton.vue";

export default {
	name: 'MailingSettings',
	components: {
		DefaultButton,
		DefaultCardLayout
	},
	props: {},
}
